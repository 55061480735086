import { Component, OnInit } from '@angular/core';

declare var renderFighter: any;

@Component({
  selector: 'app-fighter',
  templateUrl: './fighter.component.html',
  styleUrls: ['./fighter.component.scss']
})
export class FighterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.loadScript('../assets/js/three/three.js');
    this.loadScript('../assets/js/three/GLTFLoader.js');
    this.loadScript('../assets/js/three/OrbitControls.js');
    this.loadScript('../assets/js/three/TDSLoader.js');
    this.loadScript('../assets/js/legostarfightermoving.js');
    // this.startAnimation();
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  public startAnimation() {
    renderFighter();
  }
}
