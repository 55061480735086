import { Component, OnInit } from '@angular/core';

// declare var renderFighter: any;

@Component({
  selector: 'app-mathfighter',
  templateUrl: './math.component.html',
  styleUrls: ['./math.component.scss']
})
export class MathComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.loadScript('../assets/js/mathfighter.js');
    // this.startAnimation();

  }


  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  /*
  public startAnimation() {
    renderFighter();
  }
   */
}
