import {Component, Inject, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';
// import * as THREE from 'three-full';


declare var renderOrbit: any;
// declare var THREE: any;




@Component({
  selector: 'app-orbit',
  templateUrl: './orbit.component.html',
  styleUrls: ['./orbit.component.scss']
})
export class OrbitComponent implements OnInit {

  constructor() {

  }


  ngOnInit() {
    this.loadScript1('../assets/js/three/three.js');
    this.loadScript1('../assets/js/three/GLTFLoader.js');
    this.loadScript1('../assets/js/three/OrbitControls.js');
    this.loadScript1('../assets/js/three/TDSLoader.js');
    this.loadScript1('../assets/js/orbit.js');
    // this.startAnimation1();
  }

  public loadScript1(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  public startAnimation1() {
    renderOrbit();
  }
}

