import { Component, OnInit } from '@angular/core';

declare var renderFighter: any;

@Component({
  selector: 'app-fightershow',
  templateUrl: './fightershow.component.html',
  styleUrls: ['./fightershow.component.scss']
})
export class FightershowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.loadScript('../assets/js/three/three.js');
    this.loadScript('../assets/js/three/GLTFLoader.js');
    this.loadScript('../assets/js/three/OrbitControls.js');
    this.loadScript('../assets/js/three/TDSLoader.js');
    this.loadScript('../assets/js/legostarfightershow.js');
    // this.startAnimation();
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  public startAnimation() {
    renderFighter();
  }
}
