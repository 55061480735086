import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatTabsModule} from '@angular/material/tabs';
import { OrbitComponent } from './orbit/orbit.component';
import { FighterComponent } from './fighter/fighter.component';
import { MathComponent} from './math/math.component';
import {FightershowComponent} from './fightershow/fightershow.component';

@NgModule({
  declarations: [
    AppComponent,
    OrbitComponent,
    FighterComponent,
    MathComponent,
    FightershowComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatTabsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
